#
# ME Pro JS commons
#

import Rails from '@rails/ujs'
import Dropzone from 'dropzone'
import Slideout from 'slideout'

# Force a dom render to have proper transitions
$.fn.extend forceDomRendering: ->
  @get(0).clientHeight
  @

# JQuery findIncludeSelf
$.fn.extend findIncludeSelf: (selector) -> @find(selector).addBack(selector)

$.me ||= {}

$.extend $.me, {

  # Init objects
  init: (objects...) ->
    @[object].init() for object in objects

  # Execute a function by name
  executeByName: (name, context=@, args...) ->
    namespaces = name.split '.'
    fn         = namespaces.pop()

    try
      context = context[namespace] for namespace in namespaces
      throw(false) unless $.isFunction context[fn]
    catch e
      return false

    context[fn].apply context, args

  # Execute the action callback
  actionCallback: (controller, action) ->
    $.me.executeByName "controllers.#{controller}.#{action}_act" if controller && action

  # Execute the action callback from xhr object
  actionCallbackXhr: (xhr) -> @actionCallback xhr.getResponseHeader('X-Controller'), xhr.getResponseHeader('X-Action')

  # Generic queue class
  Queue: class
    constructor: (@_data = []) ->
    isEmpty:       => @_data.length == 0
    enqueue: (obj) => @_data.push obj
    shift:         => @_data.shift()
    clear:         => @_data = []
    size:          => @_data.length

  # Flash message
  flashMessage:
    init: ->
      @reset()
      $(document).on 'ajax:success', (e) =>
        xhr  = e.detail[2]
        data = xhr.responseText
        unless data && typeof data is 'string' && data.match(/^Turbolinks/)
          @_createFromXhr(xhr)
      $(document).on 'ajax:error', (e) => @_createFromXhr(e.detail[2])

    reset: ->
      @_queue      = new $.me.Queue
      @_displaying = false
      @

    create: (message, type='notice') ->
      if @_displaying
        @_queue.enqueue arguments
      else
        @_node().toggleClass 'message-flash--alert', type is 'alert'
        @_message().text message
        @_show()

    createFromDom: ->
      @_show() if !@_displaying && @_message().text()

    _createFromXhr: (xhr) ->
      if xhr.getResponseHeader('X-Message')
        @create decodeURIComponent(escape(xhr.getResponseHeader('X-Message'))), xhr.getResponseHeader('X-Message-Type')

    _node: -> $ '[data-flash-message]'

    _message: -> @_node().find 'span'

    _show: ->
      @_displaying = true
      @_node().forceDomRendering().addClass('is-show')
      setTimeout =>
        @_node().removeClass 'is-show'
        @_node().one 'transitionend', => @_onHide()
      , 3000

    _onHide: ->
      @_node().removeClass 'message-flash--alert'
      @_message().text ''
      @_displaying = false
      @create.apply @, @_queue.shift() unless @_queue.isEmpty()


  # Extend UJS : update DOM on ajax:success with the following actions : update, prepend, append, replace, remove
  # A selector should be provided in the data attribute
  # A replace action without selector will replace the whole template
  ujsExtended:
    init: ->
      $(document).on 'ajax:success', '[data-update],[data-prepend],[data-append],[data-replace],[data-remove]', (e) ->
        [data, status, xhr] = e.detail
        unless xhr.responseText and typeof xhr.responseText is 'string' and xhr.responseText.match(/^Turbolinks/)
          if elmt = $(e.target).data 'remove'
            $(elmt).trigger('me:domRemove').remove()
          else
            if (elmt = $(e.target).data 'update')?
              if elmt
                $(elmt).html xhr.responseText
              else
                elmt = '[data-main-container]'
                $(elmt).html xhr.responseText
                window.scrollTo 0,0
                $.me.actionCallbackXhr xhr
            else if elmt = $(e.target).data 'prepend' then $(elmt).prepend xhr.responseText
            else if elmt = $(e.target).data 'append'  then $(elmt).append xhr.responseText
            else if elmt = $(e.target).data 'replace' then elmt = $(xhr.responseText).replaceAll(elmt)

            if elmt
              $(elmt).get(0).scrollIntoView({ behavior: 'smooth', block: 'start' }) if $(e.target).is('[data-scroll-to-elmt]')
              $(elmt).trigger 'me:domChanged'

  # Ajax file upload using FormData
  multipart:
    init: ->
      $(document).on 'submit', 'form[data-multipart]', (e) ->
        f = $(e.target)
        $.ajax({
          url: f.attr('action'), data: new FormData(f.get(0)), cache: false, contentType : false, processData : false, type: 'POST',
          beforeSend: ->
            $(document).on 'click.lock', (e) ->
              $.me.flashMessage.create I18n.t('js.upload.lock'), 'alert'
              e.preventDefault()
            if Rails.fire(f[0], 'ajax:beforeSend')
              f.find('button').prop 'disabled', true
            else
              false
          ,
          complete: (data, status, xhr) ->
            $(document).off 'click.lock'
            f.find('button').prop 'disabled', false
            f.trigger jQuery.Event('ajax:complete', { detail: [data, status, xhr] })
          ,
          success: (data, status, xhr) ->
            f.trigger jQuery.Event('ajax:success', { detail: [data, status, xhr] })
          ,
          xhr: ->
            xhr = $.ajaxSettings.xhr()
            xhr.upload.addEventListener 'progress', (e) ->
              f.trigger 'me:upload:progress', [ e.loaded, e.total ]
            , false
            xhr
        })
        false

  # Mobile slideout
  mobileSlideout:
    init: ->
      $(document).on 'turbolinks:load', (e) => @_create() if @_isMobileView()
      $(window).on 'resize', (e) => @_resize()

    object: null

    _selectors:
      panel: '#panel'
      menu: '#menu'
      trigger: '[data-menu-trigger]'

    _create: ->
      return unless $("#{@_selectors.panel},#{@_selectors.menu}").length
      $('html').removeClass 'slideout-open'
      $(@_selectors.trigger).click => @object.toggle()
      @object = new Slideout(
        panel: $(@_selectors.panel).get(0),
        menu:  $(@_selectors.menu).get(0),
        side: 'right', padding: 230, tolerance: 70
      ).on('beforeopen', =>
        $(@_selectors.trigger).addClass('is-active')
      ).on('beforeclose', =>
        $(@_selectors.trigger).removeClass('is-active')
      )

    _resize: ->
      if @_isMobileView() and @object is null
        @_create()
      else if !@_isMobileView() and @object isnt null
        @object.close().destroy()
        $(@_selectors.trigger).off()
        @object = null

    _isMobileView: -> $(window).outerWidth() <= 1020

  # Infinite pagination
  infiniteScrollPagination:
    init: ->
      $(document).on 'me:domChanged', (e) =>
        $(window).off '.paginate'
        $(@_selector).each (i, elmt) => @_paginate($(elmt))

    _selector: '[data-paginate]'

    _paginate: (elmt) ->
      $(window).on 'scroll.paginate', ->
        if !elmt.data('lock') && $(window).scrollTop() + $(window).height() - elmt.offset().top - elmt.outerHeight(true) >= 0
          elmt.data('lock', true).addClass('loader--is-shown')
          elmt.data 'page', (parseInt(elmt.data 'page') || 1) + 1
          $.get elmt.data('paginate'), { page: elmt.data('page') }, (data) ->
            elmt.append(data).data('lock', false) if data.trim() != ''
            elmt.removeClass('loader--is-shown')

  # Field char counter
  fieldCharCounter:
    init: ->
      $(document).on 'keyup', @_selector, (e) => @_count($(e.target))

      $(document).on 'me:domChanged', (e) =>
        $(@_selector).each (i, elmt) => @_count($(elmt))

    _data_attr: 'char-counter'
    _selector: '[data-char-counter]'

    _count: (target) -> $(target.data(@_data_attr)).text(I18n.t('js.char_count', { count: target.val().length }))

}

# Initialize objects
$.me.init 'flashMessage', 'ujsExtended', 'multipart', 'mobileSlideout', 'infiniteScrollPagination', 'fieldCharCounter'

# Document events
$(document).on 'submit', 'form[method=get]', (e) ->
  e.preventDefault()
  Turbolinks.visit(@action + (if @action.indexOf('?') == -1 then '?' else '&') + $(@).serialize())

# Field With Errors
$(document).on 'change', '.field_with_errors', (e) ->
  if $(e.currentTarget).siblings('.error-message').remove().length is 0
    $(e.target).closest('.check,.select').siblings('.error-message').remove()
  $(e.currentTarget).replaceWith $(e.currentTarget).children()

# Page loaded
$(document).on 'turbolinks:load', ->
  I18n.locale = document.documentElement.lang

  $.me.flashMessage.reset().createFromDom()

  $.me.actionCallback $('body').data('controller'), $('body').data('action')

  # Trigger a dom change for custom components
  $(document).trigger 'me:domChanged'

# Disable auto discover for all elements
Dropzone.autoDiscover = false if Dropzone
