#
# Meeting-Engine Modal box
#

$.extend $.me, {

  Modal: class
    constructor: (type, options) ->
      @id    = "modal_#{Date.now()}"
      @_node = switch type
        when 'alert'   then @_alert options['title'], options['message'], options['callback']
        when 'confirm' then @_confirm options['title'], options['message'], options['callback']
        when 'content'
          $(document).on "ajax:success.#{@id}", "##{@id}", (e) =>
            [data, status, xhr] = e.detail
            try
              if $(xhr.responseText).findIncludeSelf('[data-modal="body"]').length
                @_removeStyles().addClass($(e.target).data('modal-style')) if $(e.target).data('modal-style')
                elmt = @_node.find '[data-modal="content"]'
                elmt.html(xhr.responseText).trigger 'me:domChanged'
                $.me.actionCallbackXhr xhr
                @_resetPositionCache()
                @_position()
              else
                @destroy()
          @_create options['content'], options['style']

      # TODO : Modal, Resize and Touch Event
      $(window).on "resize.#{@id}", => @_position()

      $(document).on "page:before-unload.#{@id}", => @destroy()
      @_node.on "me:close.#{@id}", => @destroy()
      @_node.on 'click', '[data-modal="close"]', (e) =>
        e.preventDefault()
        @destroy()
      @_position()

    show: -> @_node.addClass 'modal-show'

    destroy: ->
      $([window, document]).off ".#{@id}"
      @_node.remove()

    # Static

    @init: ->
      # Remote modals
      $(document).on 'ajax:success', '[data-modal="true"]',  (e) =>
        [data, status, xhr] = e.detail
        mt      = $(e.target).data 'modal-style'
        options = {content: xhr.responseText}
        options = $.extend(options, {style: mt}) if mt
        (new @ 'content', options).show()
        $.me.actionCallbackXhr xhr

      # Inline modals
      @_createFromInlineContent()
      $(document).on 'turbolinks:load', => @_createFromInlineContent()

      # Override Rails confirms
      $(document).on 'confirm', (e) ->
        element = $(e.target)
        return true unless element.attr('data-confirm')
        $.me.confirm element.data('title'), element.data('confirm'), ->
          element.removeAttr('data-confirm')
          element.get(0).click()
        false

    # Private

    @_createFromInlineContent: ->
      $('[data-modal="inline"]').each (i, v) =>
        c = $(v).detach()
        mt      = c.data 'modal-style'
        options = {content: c.first().html()}
        options = $.extend(options, {style: mt}) if mt
        (new @ 'content', options).show()

    _create: (content, style='') ->
      $ """
        <div id="#{@id}" class="modal #{style}">
          <div data-modal="content" class="modal__dialog">#{content}</div>
          <div data-modal="close" class="modal__overlay"></div>
        </div>
        """
        .appendTo('body').trigger 'me:domChanged'

    _content: (title, body, footer) ->
      """
      <div data-modal="header" class="modal__header">
        <div class="modal__title">#{title}</div>
        <button data-modal="close" class="modal__close button button--secondary button--sm button--icon-only">
          <div class="button__icon icon icon--close"></div>
        </button>
      </div>
      <div data-modal="scrollbox" class="modal__scrollbox">
        <div data-modal="body" class="modal__body">
          <div class="modal__content">
            #{body}
          </div>
        </div>
      </div>
      <div data-modal="footer" class="modal__footer">
        #{footer}
      </div>
      """

    _alert: (title, message, callback) ->
      footer = """
               <button data-modal="close" class="button button--primary button--fix">#{I18n.t 'common.ok'}</button>
               """
      node = @_create @_content(title, message, footer)
      node.find('[data-modal="close"]').one 'click', (e) => callback() if callback
      node

    _confirm: (title, message, callback) ->
      footer = """
              <button data-modal="confirm" class="button button--primary button--fix">#{I18n.t 'common.yes'}</button>
              <button data-modal="close" class="button button--secondary button--fix">#{I18n.t 'common.no'}</button>
               """
      node = @_create @_content(title, message, footer)
      node.find('[data-modal="confirm"]').one 'click', (e) =>
        e.preventDefault()
        @destroy()
        callback()
      node

    _position: ->
      pad   = 20
      @_m   ||= @_node.children(':first')
      @_ms  ||= @_m.find('[data-modal="scrollbox"]')
      @_mfh ||= @_m.outerHeight() - @_ms.outerHeight()
      wh    = $(window).outerHeight()

      if wh - pad * 2 < @_mfh + @_ms.prop('scrollHeight')
        @_m.css {top: pad, 'margin-top': 0}
        @_ms.outerHeight(wh - @_mfh - pad * 2, true)
        @_node.addClass 'modal--with-scroll'
      else
        @_node.removeClass 'modal--with-scroll'
        @_m.css {top:'50%', 'margin-top': -@_m.outerHeight()/2}

      @_m.css {left: -@_m.outerWidth()/2}

      @_node

    _resetPositionCache: ->
      @_ms = @_mfh = null

    _removeStyles: ->
      @_node.removeClass 'small normal large nopad'
      @_node


  # Helpers

  alert: (title, message, onCloseCallback) ->
    (new @Modal 'alert', {title: title, message: message, callback: onCloseCallback}).show()

  confirm: (title, message, onConfirmCallback) ->
    (new @Modal 'confirm', {title: title, message: message, callback: onConfirmCallback}).show()

}

$ -> $.me.init 'Modal'
