#
# Controllers / actions specific javascript
#

import Rails from '@rails/ujs'
import Dropzone from 'dropzone'
import Sortable from 'sortablejs'
import flatpickr from 'flatpickr'
import Quill from 'quill'

$.extend true, $.me, {

  controllers:

    clubs:
      new_act: ->
        $('[data-city-by-zip=zip-code]').selectCityByZip()

      create_act: -> $.me.controllers.clubs.new_act()

      edit_act: ->
        $('[data-schedule-toggle-close]').click (e) ->
          $(e.target).parents('[data-schedule-day]').find('select').val('')

        $('[data-schedule-day] select').change (e) ->
          unless $(e.target).val() == ''
            $(e.target).parents('[data-schedule-day]').find('[data-schedule-toggle-close]').prop('checked', false)

      update_act: -> $.me.controllers.clubs.edit_act()

    club_galleries:
      index_act: -> $.me.controllers.club_galleries.media_list()

      edit_act: -> $.me.controllers.club_galleries.media_list()

      update_act: -> $.me.controllers.club_galleries.edit_act()

      media_list: ->
        $('[data-dropzone]').each ->
          node = $(@)
          dz = new Dropzone(node[0], {
            url: node.data('url'),
            timeout: 5 * 60 * 1000,
            headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
            dictDefaultMessage: node.children().detach().html()
          })
          dz.on 'success', (file, data) ->
            setTimeout ->
              dz.removeFile(file)
              $('[data-media]').append(data)
            , 1500
          dz.on 'error', (file, response) ->
            dz.removeFile(file)
            $.me.flashMessage.create response.error, 'alert'

        Sortable.create $('[data-media]')[0], onUpdate: (e) ->
          $.ajax $(e.item).data('update-url'), { method: 'PUT', data: { "#{$(e.item).data('position-field')}": e.newIndex + 1 }}

    photos:
      edit_act: ->
        $('#photo_mediacapable_id').change (e) -> $('#photo_mediacapable_type').val($(e.currentTarget).find(":selected").data('mediacapable-type'))

    videos:
      index_act: ->
        $.me.controllers.club_galleries.media_list()

        timer = null
        $('[data-strip]').on('mouseover', (e) ->
          timer = setInterval ->
            $(e.currentTarget).attr 'data-strip', ($(e.currentTarget).attr('data-strip') + 1) % 7
          , 500
        ).on('mouseout', -> clearInterval(timer))

    club_evenings:
      new_act: -> $.me.controllers.club_evenings.edit_act()

      create_act: -> $.me.controllers.club_evenings.edit_act()

      edit_act: ->
        $('#club_evening_date').flatpickr(locale: I18n.locale, dateFormat: 'd/m/Y', minDate: Date.now())
        $('[data-file-select]').change (e) ->
          $(e.currentTarget).find('span').text($(e.currentTarget).data('file-selected'))
          $('[data-file-unselect]').show()
          $('[data-src-photo]').remove()
        $('[data-file-unselect]').click (e) ->
          e.preventDefault()
          $('[data-file-select] input').val('')
          $('[data-file-select] span').text($(e.currentTarget).hide().data('file-unselect'))
        # Text editor
        quill = new Quill('[data-editor] div', {
          modules: {
            toolbar: [['bold', 'italic', 'underline', 'strike'], [{ 'list': 'ordered'}, { 'list': 'bullet' }], ['clean']]
          },
          placeholder: 'Description de votre club...', theme: 'snow'
        })

        $('.form-group--price-by-kind').on 'change', ':text', (e) ->
          $(':checkbox', $(e.delegateTarget)).prop 'checked', $(e.target).val() != ''

        $('form').submit -> $('#club_evening_description').val(quill.container.firstChild.innerHTML)

      update_act: -> $.me.controllers.club_evenings.edit_act()

      duplicate_act: ->
        $('#duplicate_form_starts_at').flatpickr(locale: I18n.locale, dateFormat: 'd/m/Y', minDate: Date.now())

    partnerships:
      method4_act: ->
        $('[data-fb-share]').click (e) -> !window.open(e.currentTarget.href, 'Facebook', 'width=640,height=580')

      options_act: ->
        $('[data-option]').on 'change', (e) ->
          if parseInt($(e.target).val()) == 3
            $('[data-club-evenings] input[type=radio]:first').prop 'checked', true
          else
            $('[data-club-evenings] input[type=radio]').prop 'checked', false
          Rails.fire $(e.target).parents('form').get(0), 'submit'

        $('[data-club-evenings]').change (e) ->
          $('[data-option=3]').prop 'checked', true
          Rails.fire $(e.target).parents('form').get(0), 'submit'

        $('[data-zone-selection]').on 'change', 'select', (e) ->
          $(e.target).parents('.select').nextAll('.select').find('select').val('')
          $('#zone_selected').remove()
          Rails.fire $(e.target).parents('form').get(0), 'submit'

}